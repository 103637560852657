import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Header from "../components/header";
import Seo from "../components/seo";
import TwoColumnLayout from '../components/twoColumnLayout';
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageGrid2x2 = styled(GatsbyImage)`
 width: 100%;
`

const ImageGrid1x1 = styled(GatsbyImage)`
 width: 100%;
 grid-column-start: 1;
 grid-column-end: 3;
 ${MEDIA.PHONE`
   grid-column-start: 1;
   grid-column-end: 1;
   `};
`

const GridContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(50% - 2rem, 0fr));
grid-gap: 2rem;
margin: 2rem;
${MEDIA.PHONE`
  grid-template-columns: repeat(auto-fill, minmax(100%, 0fr));
  `};
`

const TextContainer = styled.div`
position: fixed;
top: 0;
bottom: 0;
max-width: 533px;
z-index: 1000;
overflow-y: auto;
-ms-overflow-style: none;
scrollbar-width: none;
padding-right: 2rem;
padding-top: 2rem;
&::-webkit-scrollbar {
    display: none;
}
${MEDIA.TABLET`
    width: 100%;
    position: static;
    align-items: left;
    padding: 2rem;
    padding-bottom: 0;
    z-index: 10;
  `};
${MEDIA.PHONE`
  width: 100%;
  position: static;
  align-items: left;
  padding: 2rem;
  padding-bottom: 0;
  z-index: 10;
  `};
`

const UMAI = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "UmaiDriedFoods"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)
  const images = [];
  for (let i = 0; i < data.allFile.edges.length; i++) {
    images[i] = getImage(data.allFile.edges[i].node)
  }

  return(
    <div>
        <TwoColumnLayout
          Column1Size="66"
          Column1Content={
              <GridContainer>
                <ImageGrid2x2 image={images[1]} alt={data.allFile.edges[1].node.name} key={1}/>
                <ImageGrid2x2 image={images[2]} alt={data.allFile.edges[2].node.name} key={2}/>
                <ImageGrid2x2 image={images[3]} alt={data.allFile.edges[3].node.name} key={3}/>
                <ImageGrid2x2 image={images[0]} alt={data.allFile.edges[0].node.name} key={0}/>
                <ImageGrid1x1 image={images[5]} alt={data.allFile.edges[5].node.name} key={5}/>
                <ImageGrid1x1 image={images[6]} alt={data.allFile.edges[6].node.name} key={6}/>
                <ImageGrid1x1 image={images[7]} alt={data.allFile.edges[7].node.name} key={7}/>
                <ImageGrid1x1 image={images[8]} alt={data.allFile.edges[8].node.name} key={8}/>
                <ImageGrid1x1 image={images[4]} alt={data.allFile.edges[4].node.name} key={4}/>
              </GridContainer>
          }
          Column2Size="33"
          Column2Content={
            <TextContainer>
            <Seo title="Umai Dried Foods" />

            <h2>
            <i>UMAI Dried Foods</i>
            <br /><br />
            {'UMAI is a dried snack manufacturer with a variety of dried seafood snacks.'}
            <br /><br />
            {"The packaging is designed to align with the brand's values of liveliness and fun. Bold patterns and colours are used to represent these values and engage a wide audience. Each box is designed to have a unique shape and pattern that represents the ingredients of the product."}
            <br /><br />
            {"In 2021 UMAI won a merit for the AGDA 2021 student packaging awards"}
            </h2>
          </TextContainer>
          }
          />
          <Header/>


</div>
  )
}




export default UMAI
